import { useMemo, useState } from 'react';
import './App.css';
// @ts-ignore
import cadesplugin from 'crypto-pro-cadesplugin';
import {API, getPdfConfig} from "./axios";
import {Button, Flex, Form, Select, Upload, UploadFile, UploadProps} from "antd";
import { useForm } from "antd/lib/form/Form";
import {clickDownloadLink} from "./clickDownloadLink";

const useDoCertsList = () =>
  useMemo(async () => {
    const certsApi = await cadesplugin();
    const certsList = await certsApi.getCertsList();

    // @ts-ignore
    const list = certsList.map(({subjectInfo, thumbprint}) => ({
      value: thumbprint,
      label: subjectInfo
    }));
    return list;
  }, []);

function App() {
  const [listCert, setListCert] = useState([{value: "подпись", label: "подпись"}]);
  const [certificate, setCertificate] = useState('');
  const [file, setFile] = useState<UploadFile[] | null>(null);
  const [hash, setHash] = useState('');
  const [form] = useForm();

  const uploadProps: UploadProps = {
    name: 'act',
    accept: '.pdf,.docx',
    maxCount: 1,
    customRequest: ({ file, onSuccess }) =>
      setTimeout(() => {
        onSuccess!('ok');
      }, 0),
  };

  const handleChangeFile = (e: any) => {
    return e?.fileList;
  };

  const getHash = async (values: any) => {

    const certsApi = await cadesplugin();
    const currentCert = await certsApi.currentCadesCert(values.thumbprint);
    const publicKey = await currentCert.PublicKey();
    const algorithm = await publicKey.Algorithm;
    const value = await algorithm.Value;
    console.log(value);

    const formData = new FormData();
    if (values.file && values.file.length > 0) {
      formData.append('file', values.file['0'].originFileObj);
    }
    const headers = {headers: {'Content-Type': 'multipart/form-data'}};
    API.post('/backend/api/v1/hash', formData, headers)
      .then((response) => {
        setHash(response.data.hash);
        // getSign(values.thumbprint, response.data.hash, values.file['0'].originFileObj);
      })

  }
  const getSign = async (customer: boolean) => {
    const fileJK = form.getFieldValue('file');
    const thumbprint = form.getFieldValue('thumbprint');

    const certsApi = await cadesplugin();
    const sign = await certsApi.signBase64(thumbprint, hash);
    const reportData = {
      "profile": {
        "id": customer ? 24 : 6,
        "name": customer ? "профиль" : "Верум",
        "surname": customer ? "заказчика" : "Софт",
        "email": customer ? "logarifm05@yandex.ru" : "verum.software@gmail.com",
      },
      "signature": sign,
    };
    console.log(reportData)
    const formData = new FormData();
    if (fileJK) {
      formData.append('file', fileJK[0].originFileObj);
    }

    formData.append('json', JSON.stringify(reportData));
    const headers = {headers: {'Content-Type': 'multipart/form-data'}};
    getPdfConfig.post('/backend/api/v1/jcp', formData, headers)
      .then((resp) => {
        const blob = resp.data;
        clickDownloadLink(blob, `Подписанный договор.pdf`);
      })
      .catch((error) => console.log(error));
  }

  useDoCertsList()
    .then(setListCert)

  return (
    <Form id="form" form={form} colon={false} layout="vertical" onFinish={getHash}>
      <Form.Item
        name="file"
        valuePropName="fileList"
        getValueFromEvent={handleChangeFile}
      >
        <Upload {...uploadProps}>
          <Button>Click to Upload</Button>
        </Upload>
      </Form.Item>
      <Form.Item label="Выберите сертификат" name="thumbprint">
        <Select options={listCert} />
      </Form.Item>
      <Flex gap="large" wrap>
        <Button type="primary" htmlType="submit">Получить хеш</Button>
        <Button type="dashed" color="primary" htmlType="button" onClick={() => getSign(true)} disabled={!hash}>Подписать договор заказчик</Button>
        <Button type="dashed" color="primary" htmlType="button" onClick={() => getSign(false)} disabled={!hash}>Подписать договор перевозчик</Button>
      </Flex>
    </Form>
  );
}

export default App;
