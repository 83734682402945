import axios from 'axios';

export const API = axios.create({
  baseURL: process.env.BASE_API_URL,
  withCredentials: true,
});
export const getPdfConfig = axios.create({
  baseURL: process.env.BASE_API_URL,
  withCredentials: true,
  responseType: 'blob',
});