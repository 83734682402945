export const clickDownloadLink = (blob: Blob, name: string) => {
  const url = window.URL.createObjectURL(new Blob([blob],{type: 'application/pdf;base64'}));
  const downloadLink = document.createElement('a');
  downloadLink.style.display = 'none';
  downloadLink.href = url;
  downloadLink.download = name;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  window.URL.revokeObjectURL(url);
};
